<template>
  <div class="dashboard">
    <header class="header">
      <h1 class="title">Upload Your Videos</h1>
      <button @click="logout" class="logout-button">Logout</button>
    </header>
    <main class="content">
      <form @submit.prevent="uploadVideo" class="upload-form">
        <input
          type="text"
          v-model="videoTitle"
          placeholder="Video Title"
          required
          class="form-input"
        />
        <input
          type="file"
          @change="handleFileUpload"
          accept="video/*"
          class="form-input"
        />
        <button type="submit" :disabled="!selectedFile" class="submit-button">Upload</button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </form>
      <section v-if="videos.length" class="video-gallery">
        <div v-for="video in videos" :key="video.id" class="video-card">
          <video :src="video.url" controls class="video-player"></video>
          <div class="video-title">{{ video.name }}</div>
          <button @click="deleteVideo(video.id)" class="delete-button">Delete</button>
        </div>
      </section>
      <section v-else class="no-videos">
        <p>No videos uploaded yet.</p>
      </section>
    </main>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserDashboard',
  data() {
    return {
      videoTitle: '',
      selectedFile: null,
      videos: [],
      errorMessage: '',
    };
  },
  async created() {
    await this.fetchVideos();
  },
  methods: {
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    async uploadVideo() {
      this.errorMessage = '';

      const formData = new FormData();
      formData.append('video', this.selectedFile);
      formData.append('title', this.videoTitle);

      try {
        const response = await axios.post('https://admin.stepp2p.com/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.success) {
          this.videoTitle = '';
          this.selectedFile = null;
          await this.fetchVideos();
        } else {
          this.errorMessage = response.data.message || 'Upload failed';
        }
      } catch (error) {
        this.errorMessage = 'An error occurred while uploading the video';
        console.error('Error uploading video', error);
      }
    },
    async fetchVideos() {
      try {
        const response = await axios.get('https://admin.stepp2p.com/videos');
        this.videos = response.data.videos || [];
      } catch (error) {
        console.error('Error fetching videos', error);
      }
    },
    async deleteVideo(videoId) {
      try {
        const response = await axios.delete(`https://admin.stepp2p.com/videos/${videoId}`);
        if (response.data.success) {
          this.videos = this.videos.filter(video => video.id !== videoId);
        } else {
          this.errorMessage = 'Failed to delete video';
        }
      } catch (error) {
        this.errorMessage = 'An error occurred while deleting the video.';
        console.error('Error deleting video', error);
      }
    },
    logout() {
      localStorage.removeItem('user');
      this.$router.push('/login');
    }
  },
};
</script>

<style scoped>
.dashboard {
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ddd;
}

.title {
  font-size: 2rem;
  color: #333;
}

.logout-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  background-color: #dc3545;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #c82333;
}

.content {
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  gap: 1rem;
  margin-bottom: 2rem;
}

.form-input {
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
}

.submit-button {
  padding: 0.75rem;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:disabled {
  background-color: #6c757d;
}

.submit-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.video-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.video-card {
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

.video-player {
  width: 100%;
}

.video-title {
  padding: 0.5rem;
  background-color: #f4f4f4;
  text-align: center;
  font-size: 1rem;
  color: #333;
}

.delete-button {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0 0 8px 0;
  background-color: #dc3545;
  color: #fff;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #c82333;
}

.no-videos {
  text-align: center;
  color: #666;
  font-size: 1.25rem;
}

.error-message {
  color: #dc3545;
  font-weight: bold;
}
</style>
