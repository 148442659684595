<template>
  <div class="login">
    <h2 class="login-title">Login</h2>
    <form @submit.prevent="loginUser" class="login-form">
      <input
        type="text"
        v-model="username"
        placeholder="Username"
        required
        class="login-input"
      />
      <input
        type="password"
        v-model="password"
        placeholder="Password"
        required
        class="login-input"
      />
      <button type="submit" class="login-button">Login</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserLogin',
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    async loginUser() {
      try {
        const response = await axios.post('https://admin.stepp2p.com/login', {
          username: this.username,
          password: this.password,
        });

        if (response.data.success) {
          localStorage.setItem('user', this.username); // เก็บชื่อผู้ใช้ใน localStorage
          this.$router.push('/dashboard');
        } else {
          alert('Login failed');
        }
      } catch (error) {
        console.error('Error logging in', error);
      }
    },
  },
};
</script>

<style scoped>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.login-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.login-input {
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
}

.login-button {
  padding: 0.75rem;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #0056b3;
}
</style>
