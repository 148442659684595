import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      user: null,
    };
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
  },
  actions: {
    login({ commit }, user) {
      commit('setUser', user);
      localStorage.setItem('user', user);
    },
    logout({ commit }) {
      commit('clearUser');
      localStorage.removeItem('user');
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.user;
    },
    currentUser(state) {
      return state.user;
    },
  },
});

export default store;
