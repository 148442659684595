<template>
  <router-view></router-view> <!-- ใช้ router-view เพื่อแสดงคอมโพเนนต์ตามเส้นทางที่ตั้งค่า -->
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* สไตล์เพิ่มเติม */
</style>
